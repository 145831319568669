import apiUtils from '../apiUtils';

const service = {};
const serviceName = 'company';

service.getCompanies = () => {
  if (!apiUtils.isActive()) {
    return new Promise(() => null);
  }
  return apiUtils.exec(serviceName + '/');
};

service.getCompetence = () => {
  if (!apiUtils.isActive()) {
    return new Promise(() => null);
  }
  return apiUtils.exec(`${serviceName}/competence/`);
};

service.getCompany = id => {
  if (!apiUtils.isActive()) {
    return new Promise(() => null);
  }
  return apiUtils.exec(`${serviceName}/${id}`);
};

service.post = company => {
  if (!apiUtils.isActive()) {
    return new Promise(() => null);
  }
  const method = company.id ? 'PUT' : 'POST';
  return apiUtils.exec(serviceName, apiUtils.getFetchParameters(method, company));
};

service.validateCertificate = company => {
  if (!apiUtils.isActive()) {
    return new Promise(() => null);
  }
  return apiUtils.exec(`${serviceName}/validate/`, apiUtils.getFetchParameters('POST', company));
};

service.delete = company => {
  return apiUtils.exec(`${serviceName}`, apiUtils.getFetchParameters('DELETE', company));
};

export default service;
