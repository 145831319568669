const messages = {};

messages.WrnOpenRequestFound =
  'Por favor aguarde a solicitação em aberto ser finalizada antes de fazer uma nova.';
messages.WrnSelectionRequired = 'É necessário selecionar ao menos um registro.';
messages.WrnFileRequired = 'É necessário selecionar um arquivo.';
messages.ErrClientNotFound = 'Requisição inválida. Verifique se a URL está correta e atualize a página.';
messages.ErrApiNotFound = 'Não foi possível estabelecer uma conexão com a API.'
  + ' Por favor tente mais tarde ou contacte o administrador do sistema';
messages.ErrCaptcha = 'Por favor confirme que você não é um robô.';

messages.WrnAllFieldsRequired = 'É necessário preencher todos os campos.';
messages.WrnComparativeNoAssociation = 'É necessário fazer ao menos uma associação.';
messages.WrnComparativeInvalidAssociation = 'É necessário preencher todos os campos das associações.';
messages.WrnComparativeInvalidCalculation = 'É necessário preencher todos os campos das colunas calculadas.';
messages.WrnComparativeDescriptionRequired = 'É necessário preencher uma descrição antes de prosseguir.';
messages.WrnComparativeSqlRequired = 'É necessário selecionar uma sentença SQL e validá-la antes de prosseguir.';
messages.WrnComparativeXlsRequired = 'É necessário carregar uma planílha antes de prosseguir.';
messages.WrnComparativeSqlFieldsRequired = 'É necessário preencher todos os campos antes de validar.';
messages.WrnComparativeChangeSql = 'Não é possível validar nova consulta pois já existem mapeamentos.';
messages.WrnComparativeChangeXls = 'Não é possível limpar o comparativo Excel pois já existem mapeamentos.';
messages.ErrComparativeSave = 'Erro ao tentar salvar o comparativo.';
messages.ErrComparativeItemSave = 'Erro ao tentar salvar itens do comparativo.';
messages.ErrComparativeMappingSave = 'Erro ao tentar salvar o mapeamento do comparativo.';
messages.ErrMultiplasSecoes = 'O Guardian não permite múltiplas sessões por questões de segurança. '
+ 'Favor encerrar todas a sessões abertas e fazer o acesso novamente';
messages.ErrDuplicate = 'Não é possivel adicionar um item já existente.';
messages.ErrAuthSaml = 'Erro de autenticação, tente novamente mais tarde ou procure o administrador.';

export default messages;
