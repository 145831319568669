import React, { useState } from 'react';
import DgCompetence from './parameter/DgCompetence';
import DgCompany from './parameter/DgCompany';
import DgFilial from './parameter/DgFilial';
import helper from './helper/competenceHelper';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../atoms/userAtom';
import { sessionAtom } from '../../atoms/sessionAtom';
import { showHeaderCompetenceAtom } from '../../atoms/showHeaderCompetenceAtom';
import { showHeaderCompaniesAtom } from '../../atoms/showHeaderCompaniesAtom';
import { showHeaderFilialAtom } from '../../atoms/showHeaderFilialAtom';
import { competencesAtom } from '../../atoms/competencesAtom';
import { companiesAtom } from '../../atoms/companiesAtom';
import { filialsAtom } from '../../atoms/filialsAtom';
import { exibirFiltrosAtom } from '../../atoms/exibirFiltrosAtom';
import loginService from '../../services/loginServices';
import './header.css';

const DashboardHeader = () => {
  const [showHeaderCompetence] = useRecoilState(showHeaderCompetenceAtom);
  const [showCompanies] = useRecoilState(showHeaderCompaniesAtom);
  const [showFilial] = useRecoilState(showHeaderFilialAtom);
  const [competences] = useRecoilState(competencesAtom);
  const [companies] = useRecoilState(companiesAtom);
  const [filials] = useRecoilState(filialsAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const [session, setSession] = useRecoilState(sessionAtom);
  const [exibirFiltros] = useRecoilState(exibirFiltrosAtom);

  const [showCompetenceModal, setShowCompetenceModal] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [showFilialModal, setShowFilialModal] = useState(false);

  const exit = () => {
    const newSession = { ...session };
    newSession.requireValidation = false;
    newSession.logoff = true;
    setSession(newSession);
    loginService.quit();
  };

  const toogleAdministration = enter => {
    const localUser = { ...user };
    localUser.accessingAdministrationMode = enter;
    setUser(localUser);
  };

  const prepareCompetence = () => {
    let field = 'Selecione...';
    if (competences.length > 0) {
      field = competences.length > 1 ? `${helper.format(competences[0])} +${competences.length - 1}` : helper.format(competences[0]);
    }
    const component =
      <button className="link-button forcedLink guardian-header-btn" data-toggle="dropdown" onClick={() => setShowCompetenceModal(true)}>
        <i className="material-icons">date_range</i>
        <span>{field}</span>
      </button>;
    return component;
  };

  const prepareCompany = () => {
    let field = 'Selecione...';
    if (companies.length > 0) {
      field = companies.length > 1 ? `${companies[0].name} +${companies.length - 1}` : companies[0].name;
    }
    const component =
      <button data-cy="selectCompaniesButton" className="link-button forcedLink guardian-header-btn" data-toggle="dropdown" onClick={() => setShowCompanyModal(true)}>
        <i className="material-icons">work</i>
        <span>{field}</span>
      </button>;
    return component;
  };

  const prepareFilials = () => {
    let field = 'Selecione...';
    if (filials.length > 0) {
      field = filials.length > 1 ? `${filials[0].name} +${filials.length - 1}` : filials[0].name;
    }
    const component =
      <button data-cy="selectFilliasButton" className="link-button forcedLink guardian-header-btn" data-toggle="dropdown" onClick={() => setShowFilialModal(true)}>
        <i className="material-icons">location_city</i>
        <span>{field}</span>
      </button>;
    return component;
  };

  const showCompetenceParams = () => {
    if (!showHeaderCompetence) return;
    const competenceMethods = { onClose: () => setShowCompetenceModal(false) };

    const params =
      <div className='box1'>
        <DgCompetence show={showCompetenceModal} methods={competenceMethods} />
        {prepareCompetence()}
      </div>;
    return params;
  };

  const showCompanyParams = () => {
    if (!showCompanies || !exibirFiltros.exibirColigada) return;
    const companyMethods = { onClose: () => setShowCompanyModal(false) };

    const params =
      <div className='box2'>
        <DgCompany show={showCompanyModal} methods={companyMethods} />
        {prepareCompany()}
      </div>;
    return params;
  };

  const showFilialParams = () => {
    if (!showFilial || !exibirFiltros.exibirFilial || user.client.idErp !== 1) return;
    const filialMethods = { onClose: () => setShowFilialModal(false) };

    const params =
      <div className='box3'>
        <DgFilial show={showFilialModal} methods={filialMethods} />
        {prepareFilials()}
      </div>;
    return params;
  };

  const accessMode = () => {
    if (!user.accessInfo.admin) return;
    let mode =
      <button className="link-button forcedLink guardian-header-btn" onClick={() => toogleAdministration(false)}>
        <i className="material-icons">verified_user</i>
        <span>Guardian</span>
      </button>;
    if (!user.accessingAdministrationMode) {
      mode =
        <button className="link-button forcedLink guardian-header-btn" onClick={() => toogleAdministration(true)}>
          <i className="material-icons">settings</i>
          <span>Administração</span>
        </button>;
    }
    return mode;
  };

  return (
    <nav className="guardian-header">
      <div className="container-fluid">
        <div className="relative">
          <div className='parent grid-parent'>
            {showCompetenceParams()}
            {showCompanyParams()}
            {showFilialParams()}
          </div>
          <div className="guardian-header-right">
            {accessMode()}
            <Link className="forcedLink guardian-header-btn" to="/profile" >
              <i className="material-icons">person</i>
              <span>{user.userName}</span>
            </Link>
            <button className="link-button forcedLink guardian-header-btn" onClick={exit}>
              <i className="material-icons">exit_to_app</i>
              <span>Sair</span>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default DashboardHeader;
