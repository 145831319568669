import apiUtils from '../apiUtils';

const service = {};
const serviceName = 'user';

service.getUsers = () => {
  return apiUtils.exec(serviceName + '/');
};

service.getUsersShort = () => {
  return apiUtils.exec(serviceName + '/short/');
};

service.getUser = id => {
  return apiUtils.exec(`${serviceName}/${id}`);
};

service.post = user => {
  const method = user.id ? 'PUT' : 'POST';
  return apiUtils.exec(serviceName + '/', apiUtils.getFetchParameters(method, user));
};

service.reset = user => {
  return apiUtils.exec(serviceName + '/ResetPassword/', apiUtils.getFetchParameters('PUT', user));
};

service.delete = id => {
  //return apiUtils.exec(serviceName + '/', apiUtils.getFetchParameters('DELETE', user));
  return apiUtils.exec(`${serviceName}/${id}`, apiUtils.getFetchParameters('DELETE'));
};

service.getMenuUser = () => {
  return apiUtils.exec('securityProfile/menuByUser/');
};

export default service;
