import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '../menu/Menu';
import Header from '../common/Header';
import Footer from '../common/Footer';
import serviceUser from '../../api/services/userServices';
import { useRecoilState } from 'recoil';
import { showFullPageAtom } from '../../atoms/showFullPageAtom';
import { sideBarOpen } from '../../atoms/sidebarAtom';
import { RoutePermissionProvider } from '../../commons/useRoutePermission';


const App = props => {
  const [menuItens, setMenuItens] = useState([]);
  const [showFullPage] = useRecoilState(showFullPageAtom);
  const [isNavOpen] = useRecoilState(sideBarOpen);
  const MENU_ADM = 2;

  useEffect(() => {
    serviceUser.getMenuUser().then(res => {
      if (res && res.data) {
        const menuItens = res.data.filter(m => m.menuType === MENU_ADM);
        setMenuItens(menuItens);
      }
    });
  }, [props.children]);

  const getMenu = () => {
    return <Menu items={menuItens} />;
  };

  const getHeader = () => {
    return <Header />;
  };

  const getFooter = () => {
    return <Footer />;
  };

  const parentStyle = !showFullPage ? 'wrapper' : '';
  const childStyle = !showFullPage ? 'main-panel' : 'wrapper-fullpage';
  const isNavOpenClass = !isNavOpen && 'sidebar-closed main-panel-closed';

  return (
    <div className={parentStyle}>
      {getMenu()}
      <div className={`${childStyle} ${isNavOpenClass}`}>
        {getHeader()}
        {props.children}
        {getFooter()}
      </div>
    </div>
  );
};


const AppWithRoutePermission = props => {
  return (
    <RoutePermissionProvider>
      <App {...props} />
    </RoutePermissionProvider>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired
};


AppWithRoutePermission.propTypes = {
  children: PropTypes.object.isRequired
};


export default AppWithRoutePermission;
